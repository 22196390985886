import React from 'react';

import Icon from './Icon';

export default function ChevronDown({ className = '', text = 'down' }: { text?: string; className?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M7.53033 8.46967C7.23744 8.17678 6.76256 8.17678 6.46967 8.46967C6.17678 8.76256 6.17678 9.23744 6.46967 9.53033L9.46967 12.5303C9.76256 12.8232 10.2374 12.8232 10.5303 12.5303L13.5303 9.53033C13.8232 9.23744 13.8232 8.76256 13.5303 8.46967C13.2374 8.17678 12.7626 8.17678 12.4697 8.46967L10 10.9393L7.53033 8.46967Z" />
</svg>
  </Icon>;
}
